import React from "react";
import Banner from "./Home/banner";
import HomeSecond from "./Home/homeSecond";
import HomeThird from "./Home/homeThird";
import Gallery from "./Home/gallery";

function Home() {
    return (
        <>
            <Banner />
            <HomeSecond />
            <HomeThird />
            <Gallery />
        </>
    );
}

export default Home