import React from "react";
import InsuranceIMG from '../assets/images/insurance.png'
import InsureIMG1 from '../assets/images/insureimg1.jpg'
import InsureIMG2 from '../assets/images/insureimg2.jpg'
import InsureIMG3 from '../assets/images/insureimg3.webp'
import InsureIMG4 from '../assets/images/insureimg4.jpg'

function Insurance() {
    return (
        <>
            <section className="w-full bg-[#7A7A7A] h-[500px]">
                <div className="flex justify-center h-full items-center">
                    <h2 className="font-lobster text-white text-5xl">Insurance</h2>
                </div>
            </section>
            <section className="max-w-screen-xl mx-auto flex mt-20 h-[550px] space-x-10 max-lg:flex-col max-lg:h-full max-lg:space-x-0 max-lg:space-y-3 max-lg:px-10">
                <div className="w-1/3 font-offside flex-col flex space-y-5 max-lg:w-full">
                    <h2 className="text-3xl">Cheap Insurance Near You</h2>
                    <form className="flex flex-col space-y-5">
                        <div>
                            <label htmlFor="type">Type of Insurance</label>
                            <select id="type" className="w-full border border-black p-2" required>
                                <option>Motorcycle</option>
                                <option>Automotive</option>
                                <option>Homeowners</option>
                                <option>Renters</option>
                                <option>Health</option>
                                <option>Boat</option>
                                <option>Life</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="zip">Zip Code</label>
                            <input type="text" id="zip" className="w-full border border-black p-2" required />
                        </div>
                        <div>
                            <label htmlFor="age">Age</label>
                            <select id="age" className="w-full border border-black p-2" required>
                                <option>16-20</option>
                                <option>21-24</option>
                                <option>25-34</option>
                                <option>35-44</option>
                                <option>45-54</option>
                                <option>55-65</option>
                                <option>65+</option>
                            </select>
                        </div>
                        <div>
                            <h2>Already Insured?</h2>
                            <div className="space-x-1">
                                <input type="radio" id="yes" name="answer" required />
                                <label htmlFor="yes">Yes</label>
                            </div>
                            <div className="space-x-1">
                                <input type="radio" name="answer" id="no" required />
                                <label htmlFor="no">No</label>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" className="w-full border border-black p-2" />
                        </div>
                        <div>
                            <input type="submit" value={'Submit'} className="cursor-pointer uppercase py-1 px-5 border-2 border-black hover:text-white hover:bg-mainColor hover:border-transparent transition-all duration-300" />
                        </div>
                    </form>
                </div>
                <div className="w-2/3 max-lg:w-full overflow-hidden rounded-3xl shadow-xl shadow-black/50 transition-all duration-300 hover:rounded-[5rem]">
                    <img src={InsuranceIMG} alt="44" className="object-cover h-full w-full" />
                </div>
            </section>
            <section className="mx-auto max-w-screen-xl mt-20">
                <h2 className="font-lobster text-center text-3xl mb-5">We insure</h2>
                <div className="flex justify-between max-lg:flex-col max-lg:justify-center max-lg:items-center max-lg:space-y-3">
                    <div className="rounded-full bg-gray-200 p-4 h-[200px] w-[200px] overflow-hidden flex justify-center">
                        <img src={InsureIMG1} className="relative rounded-full h-full w-full hover:scale-110 transition-all duration-500 invert-[0.2] hover:invert-0 cursor-pointer" alt="88"/>
                    </div>
                    <div className="rounded-full bg-gray-200 p-4 h-[200px] w-[200px] overflow-hidden flex justify-center">
                        <img src={InsureIMG2} className="relative rounded-full h-full w-full hover:scale-110 transition-all duration-500 invert-[0.2] hover:invert-0 cursor-pointer" alt="88"/>
                    </div>
                    <div className="rounded-full bg-gray-200 p-4 h-[200px] w-[200px] overflow-hidden flex justify-center">
                        <img src={InsureIMG3} className="relative rounded-full h-full w-full hover:scale-110 transition-all duration-500 invert-[0.2] hover:invert-0 cursor-pointer" alt="88"/>
                    </div>
                    <div className="rounded-full bg-gray-200 p-4 h-[200px] w-[200px] overflow-hidden flex justify-center">
                        <img src={InsureIMG4} className="relative rounded-full h-full w-full hover:scale-110 transition-all duration-500 invert-[0.2] hover:invert-0 cursor-pointer" alt="88"/>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Insurance