import React from "react";
import BannerBackground from '../../assets/images/banner.png'
import { Link } from "react-router-dom";

function Banner() {
    return (
        <>
            <section className="w-full h-[789px] bg-cover bg-center relative max-lg:bg-left max-lg:h-auto max-lg:py-20" style={{ backgroundImage: `url(${BannerBackground})` }}>
                <div className="bg-transparent bg-[radial-gradient(at_center_center,#000000_0%,#DB390E_100%)] opacity-30 h-full w-full absolute left-0 top-0 "></div>
                <div className="flex flex-col justify-center items-center h-full space-y-10 relative z-50 max-w-screen-sm mx-auto">
                    <h2 className="text-mainColor font-lobster text-7xl max-lg:text-5xl">MotorcyclePains</h2>
                    <h3 className="text-white font-lobster text-3xl border-t-2 border-white">Your source for all things Moto</h3>
                    <div className="flex justify-center space-x-3 w-full font-offside">
                        <Link to={'/blog'} className="bg-white text-black py-2 px-10 uppercase transition-all duration-300 hover:text-white hover:bg-mainColor">
                            Blog
                        </Link>
                        <Link to={'/contact'} className="bg-white text-black py-2 px-10 uppercase transition-all duration-300 hover:text-white hover:bg-mainColor">
                            CONTACT
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner