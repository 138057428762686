import React from "react";
import { Button, Card, Form } from "react-bootstrap";

const Final = ({ values, prevStep }) => {
  const {
    firstName,
    lastName,
    tel,
    email,
    moto,
    city,
    state,
    time,
    role,
    damage,
    accidentCost,
    vehicle,
    fault,
    otherInsurance,
    treatment,
    message,
  } = values;

  return (
    <>
      <Form>
        <Card style={{ marginTop: 100, textAlign: "left" }}>
          <Card.Body>
            {Object.entries(values).map(([key, value]) => (
              <input key={key} type="hidden" name={key} value={value} />
            ))}
            <p>
              <strong>First Name :</strong> {firstName}{" "}
            </p>
            <hr />
            <p>
              <strong>Last Name :</strong> {lastName}{" "}
            </p>
            <hr />
            <p>
              <strong>Phone :</strong> {tel}{" "}
            </p>
            <hr />
            <p>
              <strong>Email :</strong> {email}{" "}
            </p>
            <hr />
            <p>
              <strong>What type of motorcycle were you in? :</strong> <br /> {moto}{" "}
            </p>
            <hr />
            <p>
              <strong>City :</strong> {city}{" "}
            </p>
            <hr />
            <p>
              <strong>State :</strong> {state}{" "}
            </p>
            <hr />
            <p>
              <strong>How long ago did the accident happen? :</strong> <br /> {time}{" "}
            </p>
            <hr />
            <p>
              <strong>Were You The Driver, Passenger, or Pedestrian? :</strong> <br /> {role}{" "}
            </p>
            <hr />
            <p>
              <strong>How bad was your vehicle damaged? :</strong> <br /> {damage}{" "}
            </p>
            <hr />
            <p>
              <strong>Do you know how much your accident is worth? :</strong> <br /> {accidentCost}{" "}
            </p>
            <hr />
            <p>
              <strong>What kind of vehicle was the other party driving? :</strong> <br /> {vehicle}{" "}
            </p>
            <hr />
            <p>
              <strong>Who was at fault? :</strong> <br /> {fault}{" "}
            </p>
            <hr />
            <p>
              <strong>Did the other driver have insurance? :</strong> <br /> {otherInsurance}{" "}
            </p>
            <hr />
            <p>
              <strong>What type of medical treatment did you receive after the accident? :</strong> <br /> {treatment}{" "}
            </p>
            <hr />
            <p>
              <strong>Additional comments or suggestions :</strong> <br /> {message}{" "}
            </p>
          </Card.Body>
        </Card>
        <div className="flex justify-between w-1/5 mt-5">
          <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
            Previous
          </Button>
          <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
            Send
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Final;
