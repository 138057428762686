import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepTwelve = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    const submitFormData = (e) => {
        e.preventDefault();
        if (validator.isEmpty(values.message)) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <div className="font-offside">
                    <label htmlFor="comments">Additional comments or suggestions</label>
                    <textarea id="comments" className={`w-full border border-black p-2 ${error ? "placeholder:text-red-500" : ""}`} rows={8} required defaultValue={values.message} placeholder={error ? (`This is a required field`) : (``)}
                        onChange={handleFormData("message")}></textarea>
                </div>
                <div className="flex justify-between w-1/5 mt-5">
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default StepTwelve