import React, { Component } from "react";
import SelectUSState from 'react-select-us-states';
import Phone from "./policeReport/phoneInput";

class PoliceReport extends Component {
    constructor(props) {
        super(props);
        this.setNewValue = this.setNewValue.bind(this);
    }
    setNewValue(newValue) {
        console.log('this is the State code:' + newValue);
    }
    render() {
        return (
            <>
                <section className="bg-[#7A7A7A] h-[500px]">
                    <div className="flex justify-center h-full items-center">
                        <h2 className="font-lobster text-white text-5xl">Police Report</h2>
                    </div>
                </section>
                <section className="mx-auto max-w-screen-lg mt-5 max-lg:px-10">
                    <form className="font-offside flex flex-col space-y-3">
                        <div className="flex flex-wrap justify-between items-center">
                            <div className="w-full">
                                <label htmlFor="name">Name</label>
                            </div>
                            <div className="w-[48%]">
                                <input id="name" type="text" className="border border-black w-full p-2" required />
                                <span htmlFor="name">First</span>
                            </div>
                            <div className="w-[48%]">
                                <input id="name" type="text" className="border border-black w-full p-2" required />
                                <span htmlFor="name">Last</span>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input id="email" type="email" className="border border-black w-full p-2" required />
                        </div>
                        <div>
                            <Phone />
                        </div>
                        <div>
                            <label htmlFor="address">Address</label>
                            <input id="address" type="text" className="border border-black w-full p-2" required />
                            <span htmlFor="name">Address Line 1</span>
                        </div>
                        <div className="flex flex-wrap justify-between items-center">
                            <div className="w-[48%]">
                                <input id="city" type="text" className="border border-black w-full p-2" required />
                                <label htmlFor="city">City</label>
                            </div>
                            <div className="w-[48%]">
                                <SelectUSState id="state" className="p-2 border-2 border-black w-full" onChange={this.setNewValue} required />
                                <label htmlFor="state">State</label>
                            </div>
                            <div className="w-[48%]">
                                <input id="zip" type="number" className="border border-black w-full p-2" required />
                                <label htmlFor="zip">Zip Code</label>
                            </div>
                        </div>
                        <div>
                            <h2>I am reporting a</h2>
                            <div className="space-x-3">
                                <input type="checkbox" id="theft" name="report" required />
                                <label htmlFor="theft">Motorcycle Theft</label>
                            </div>
                            <div className="space-x-3">
                                <input type="checkbox" id="acc" name="report" required />
                                <label htmlFor="acc">Motorcycle Accident</label>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between items-center">
                            <div className="w-full font-bold">
                                <label htmlFor="person">Person Involved in Incident</label>
                            </div>
                            <div className="w-[48%]">
                                <input id="person" type="text" className="border border-black w-full p-2" required />
                                <span htmlFor="person">First</span>
                            </div>
                            <div className="w-[48%]">
                                <input id="person" type="text" className="border border-black w-full p-2" required />
                                <span htmlFor="person">Last</span>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between items-center">
                            <div className="w-full font-bold">
                                <label htmlFor="date">Date and Time of incident</label>
                            </div>
                            <div className="w-[48%]">
                                <input id="date" type="date" className="border border-black w-full p-2" required />
                                <span htmlFor="date">Date</span>
                            </div>
                            <div className="w-[48%]">
                                <input id="date" type="time" className="border border-black w-full p-2" required />
                                <span htmlFor="date">Time</span>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="location">Location of Incident</label>
                            <input id="location" type="text" className="border border-black w-full p-2" required />
                        </div>
                        <div>
                            <label htmlFor="describe">Please describe the event in detail</label>
                            <textarea id="describe" className="w-full border border-black p-2" rows={8} required></textarea>
                        </div>
                        <div>
                            <h2>Was damage done to your motorcycle or other property?</h2>
                            <div className="space-x-3">
                                <input type="radio" id="ofcourse" name="property" required />
                                <label htmlFor="ofcourse">yes</label>
                            </div>
                            <div className="space-x-3">
                                <input type="radio" id="nope" name="property" required />
                                <label htmlFor="nope">No</label>
                            </div>
                        </div>
                        <div>
                            <h2>Were you Injured?</h2>
                            <div className="space-x-3">
                                <input type="radio" id="ofcoursei" name="injured" required />
                                <label htmlFor="ofcoursei">yes</label>
                            </div>
                            <div className="space-x-3">
                                <input type="radio" id="nopei" name="injured" required />
                                <label htmlFor="nopei">No</label>
                            </div>
                        </div>
                        <div>
                            <h2 className="font-bold">GDPR Agreement</h2>
                            <div className="space-x-3">
                                <input id="accept" type="checkbox" required />
                                <label htmlFor="accept">I consent to having this website store my submitted information so they can respond to my inquiry.</label>
                            </div>
                        </div>
                        <div>
                            <input type="submit" value={'Submit'} className="cursor-pointer uppercase py-1 px-5 border-2 border-black hover:text-white hover:bg-mainColor hover:border-transparent transition-all duration-300" />
                        </div>
                    </form>
                </section>
            </>
        );
    }
}

export default PoliceReport