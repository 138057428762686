import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepSix = ({ nextStep, handleFormData, values, prevStep }) => {

    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();
        if (
            validator.isEmpty(values.damage)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <h2 className="w-full">How bad was your vehicle damaged?</h2>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="scratch" name="damage" value={"Scratched/Fender Bender"} onChange={handleFormData("damage")} required />
                    <label htmlFor="scratch">Scratched/Fender Bender</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="towed" name="damage" value={"Severely Damaged/Towed Away"} onChange={handleFormData("damage")} required />
                    <label htmlFor="towed">Severely Damaged/Towed Away</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="loss" name="damage" value={"Total Loss"} onChange={handleFormData("damage")} required />
                    <label htmlFor="loss">Total Loss</label>
                </div>
                <div className="flex justify-between w-1/5 mt-5">
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default StepSix