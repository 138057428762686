import React from "react";
import MojoLogo from '../assets/images/clients/logo.jpg'
import ShareLogo from '../assets/images/clients/ShareASale.jpg'
import VikingLogo from '../assets/images/clients/viking.jpg'
import { Link } from "react-router-dom";

function Shop() {
    return (
        <>
            <section className="bg-[#7A7A7A] h-[500px]">
                <div className="flex justify-center h-full items-center">
                    <h2 className="font-lobster text-white text-5xl">Our clients</h2>
                </div>
            </section>
            <section className="mx-auto max-w-screen-xl mt-5">
                <div className="flex justify-between items-center space-x-5 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-3">
                    <div className="w-[32%] flex justify-center">
                        <Link to={'https://mojomotosport.com/'} target="_blank" className="w-1/2 max-lg:w-full" >
                            <img src={MojoLogo} alt="88" className="w-full" />
                        </Link>
                    </div>
                    <div className="w-[32%] flex justify-center">
                        <Link to={'https://www.shareasale.com/'} target="_blank" className="w-1/2 max-lg:w-full" >
                            <img src={ShareLogo} alt="88" className="w-full" />
                        </Link>
                    </div>
                    <div className="w-[32%] flex justify-center">
                        <Link to={'https://www.vikingbags.com/'} target="_blank" className="w-1/2 max-lg:w-full" >
                            <img src={VikingLogo} alt="88" className="w-full" />
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Shop