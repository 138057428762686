import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Header from "./pages/layouts/header";
import './assets/js/script'
import Footer from "./pages/layouts/footer";
import Blog from "./pages/blog";
import React from "react";
import Contact from "./pages/contact";
import About from "./pages/about";
import Insurance from "./pages/insurance";
import InjuryClaim from "./pages/injuryClaim";
import PoliceReport from "./pages/policeReport";
import Shop from "./pages/shop";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/injury-claim" element={<InjuryClaim />} />
        <Route path="/police-report" element={<PoliceReport />} />
        <Route path="/shop" element={<Shop />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
