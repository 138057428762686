import React from "react";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import GalleryIMG1 from '../../assets/images/gallery/gallery1.jpg'
import GalleryIMG2 from '../../assets/images/gallery/gallery2.jpg'
import GalleryIMG3 from '../../assets/images/gallery/gallery3.jpg'
import GalleryIMG4 from '../../assets/images/gallery/gallery4.jpg'
import GalleryIMG5 from '../../assets/images/gallery/gallery5.jpg'
import GalleryIMG6 from '../../assets/images/gallery/gallery6.jpg'
import { Link } from "react-router-dom";

function Gallery() {
    return (
        <>
            <section className="bg-[#7A7A7A]">
                <div className="mx-auto max-w-screen-xl">
                    <LightGallery
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                        className='bg-dark'
                    >
                        <Link to={GalleryIMG1} className="w-[30.5%] mb-12 max-lg:w-full max-lg:mb-10">
                            <img alt={"xdsd"} src={GalleryIMG1} className="w-full h-full" />
                        </Link>
                        <Link to={GalleryIMG2} className="w-[30.5%] mb-12 max-lg:w-full max-lg:mb-10">
                            <img alt={"xdsd"} src={GalleryIMG2} className="w-full h-full" />
                        </Link>
                        <Link to={GalleryIMG3} className="w-[30.5%] mb-12 max-lg:w-full max-lg:mb-10">
                            <img alt={"xdsd"} src={GalleryIMG3} className="w-full h-full" />
                        </Link>
                        <Link to={GalleryIMG4} className="w-[30.5%] mb-12 max-lg:w-full max-lg:mb-10">
                            <img alt={"xdsd"} src={GalleryIMG4} className="w-full h-full" />
                        </Link>
                        <Link to={GalleryIMG5} className="w-[30.5%] mb-12 max-lg:w-full max-lg:mb-10">
                            <img alt={"xdsd"} src={GalleryIMG5} className="w-full h-full" />
                        </Link>
                        <Link to={GalleryIMG6} className="w-[30.5%] mb-12 max-lg:w-full max-lg:mb-10">
                            <img alt={"xdsd"} src={GalleryIMG6} className="w-full h-full" />
                        </Link>
                    </LightGallery>
                </div>
            </section>
        </>
    );
}

export default Gallery