import React, { useState } from "react";
import StepTwo from "./multiSteps/stepTwo";
import StepThree from "./multiSteps/stepThree";
import StepOne from "./multiSteps/stepOne";
import StepFour from "./multiSteps/stepFour";
import StepFive from "./multiSteps/stepFive";
import StepSix from "./multiSteps/stepSix";
import StepSeven from "./multiSteps/stepSeven";
import StepEight from "./multiSteps/stepEight";
import StepNine from "./multiSteps/stepNine";
import StepTen from "./multiSteps/stepTen";
import StepEleven from "./multiSteps/stepEleven";
import StepTwelve from "./multiSteps/stepTwelve";
import Final from "./multiSteps/final";

function InjuryClaim() {
    //state for steps
    const [step, setstep] = useState(1);

    //state for form data
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        tel: "",
        email: "",
        moto: "",
        city: "",
        state: "",
        time: "",
        role: "",
        damage: "",
        accidentCost: "",
        vehicle: "",
        fault: "",
        otherInsurance: "",
        treatment: "",
        message: ""
    });

    // function for going to next step by increasing step state by 1
    const nextStep = () => {
        setstep(step + 1);
    };

    // function for going to previous step by decreasing step state by 1
    const prevStep = () => {
        setstep(step - 1);
    };

    // handling form input data by taking onchange value and updating our previous form data state
    const handleInputData = (input) => (e) => {
        // input value from the form
        const { value } = e.target;

        //updating for data state taking previous state and then adding new value to create new object
        setFormData((prevState) => ({
            ...prevState,
            [input]: value
        }));
    };

    switch (step) {
        // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 1:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepOne
                                nextStep={nextStep}
                                handleFormData={handleInputData}
                                values={formData}
                            />
                        </div>
                    </div>
                </>
            );
        // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 2:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepTwo
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData}
                            />
                        </div>
                    </div>
                </>
            );
        // Only formData is passed as prop to show the final value at form submit
        case 3:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepThree
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 4:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepFour
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 5:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepFive
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 6:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepSix
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 7:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepSeven
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );


        case 8:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepEight
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 9:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepNine
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 10:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepTen
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 11:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepEleven
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 12:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepTwelve
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );

        case 13:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[500px]">
                        <div className="flex justify-center h-full items-center">
                            <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <Final
                                values={formData}
                                prevStep={prevStep}
                            />
                        </div>
                    </div>
                </>
            );


        // default case to show nothing
        default:
            return <section className="bg-[#7A7A7A] h-[500px]">
                <div className="flex justify-center h-full items-center">
                    <h2 className="font-lobster text-white text-5xl">Personal Injury Claim Support</h2>
                </div>
            </section>;
    }
}

export default InjuryClaim