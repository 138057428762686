import React from "react";
import { Link } from "react-router-dom";
import ScrollTop from "./Footer/scrollTop";

function Footer() {
    return (
        <>
            <footer className="mt-10">
                <section className="bg-[#313131] p-5">
                    <div className="mx-auto max-w-screen-xl flex justify-start space-x-5 max-lg:flex-col max-lg:space-y-3 max-lg:space-x-0">
                        <div className="w-1/3 max-lg:w-full">
                            <h2 className="text-3xl text-mainColor   font-lobster">MotorcyclePains.com</h2>
                        </div>
                        <div className="w-1/3 max-lg:w-full">
                            <h2 className="font-lobster text-white text-2xl mb-3">About Us</h2>
                            <ul className="font-offside text-white">
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/blog'}>
                                        Blogs
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/guides'}>
                                        Guides
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-1/3 max-lg:w-full">
                            <h2 className="font-lobster text-white text-2xl mb-3">Connect</h2>
                            <ul className="font-offside text-white">
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/contact'}>
                                        Contact
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'#'}>
                                        Know More
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="bg-black p-5">
                    <div className="mx-auto max-w-screen-xl flex justify-center">
                        <h2 className="font-offside text-white text-xl max-lg:text-base">
                            Copyright © {(new Date().getFullYear())} MotorcyclePains | Powered by MotorcyclePains
                        </h2>
                    </div>
                </section>
            </footer>
            <ScrollTop />
        </>
    );
}

export default Footer