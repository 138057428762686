import React from "react";
import { Link } from "react-router-dom";

function AboutSection() {
    return (
        <>
            <div className="flex max-w-screen-xl mx-auto mt-24 max-lg:flex-col max-lg:px-10 max-lg:space-y-3 max-lg:mt-12 pb-20">
                <div className="font-offside text-4xl font-bold w-1/2 max-lg:w-full">
                    <h2>About Us</h2>
                </div>
                <div className="font-offside w-1/2 text-white flex flex-col space-y-5 max-lg:w-full">
                    <p>
                        At MotorcyclePains, you will have access to our informative blogs and moto guides. From motorcycles to motocross you will find all the information that you need.
                        <br />
                        <br />
                        Feel free to explore our site and get informed.
                    </p>
                    <Link to={'/about'} className="w-[22.2%] max-lg:w-1/2 text-center py-2 px-5 bg-mainColor hover:bg-white hover:text-black transition-all duration-500 hover:-translate-y-2">
                        OUR HISTORY
                    </Link>
                </div>
            </div>
        </>
    );
}

export default AboutSection