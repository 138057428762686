import $ from 'jquery';

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
            $('#mastHeader').removeClass("fixed");
            $('#mastHeader').addClass("static");
            $('#header__section').addClass("backdrop-blur-md");
            $('#header__section').addClass("bg-black/50");
        } else if ($(this).scrollTop() < 0.1) {
            $('#mastHeader').addClass("fixed");
            $('#header__section').removeClass("backdrop-blur-md");
            $('#header__section').removeClass("bg-black/50");
        }
    });
});

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#scroll').fadeIn();
        } else {
            $('#scroll').fadeOut();
        }
    });
});

