import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepEleven = ({ nextStep, handleFormData, values, prevStep }) => {
    const [toggle, setToggle] = useState(false);
    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();
        if (validator.isEmpty(values.treatment)) {
            setError(true);
        } else {
            nextStep();
        }
    };

    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        handleFormData("treatment")(e);

        if (checked) {
            setToggle(true);
        } else {
            setToggle(false);
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <h2 className="w-full">What type of medical treatment did you receive after the accident?</h2>
                <div className="space-x-3 flex items-center">
                    <input
                        type="checkbox"
                        id="ambulance"
                        name="medical"
                        value={"Ambulance"}
                        onChange={handleFormData("treatment")}
                    />
                    <label htmlFor="ambulance">Ambulance</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input
                        type="checkbox"
                        id="emergency"
                        name="medical"
                        value={"Emergency Room"}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="emergency">Emergency Room</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input
                        type="checkbox"
                        id="office"
                        name="medical"
                        value={"Doctor's Office"}
                        onChange={handleFormData("treatment")}
                    />
                    <label htmlFor="office">Doctor's Office</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input
                        type="checkbox"
                        id="none"
                        name="medical"
                        value={"None"}
                        onChange={handleFormData("treatment")}
                    />
                    <label htmlFor="none">None</label>
                </div>
                {toggle && (
                    <div className="Open">
                        <h2 className="w-full">If you stayed at the hospital overnight, how long did you stay?</h2>
                        <div className="space-x-3 flex items-center">
                            <input
                                type="radio"
                                id="3nights"
                                name="night"
                                value={"Emergency Room: 1-3 nights"}
                                onChange={handleFormData("treatment")}
                            />
                            <label htmlFor="3nights">1-3 nights</label>
                        </div>
                        <div className="space-x-3 flex items-center">
                            <input
                                type="radio"
                                id="4nights"
                                name="night"
                                value={"Emergency Room: More than 4 nights"}
                                onChange={handleFormData("treatment")}
                            />
                            <label htmlFor="4nights">More than 4 nights</label>
                        </div>
                    </div>
                )}
                <div className="flex justify-between w-1/5 mt-5">
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default StepEleven;
