import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepFour = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();
        if (
            validator.isEmpty(values.time)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <h2 className="w-full">How long ago did the accident happen?</h2>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="30day" name="time" value={"Less Than 30 Days"} onChange={handleFormData("time")} />
                    <label htmlFor="30day">Less than 30 days ago</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="3month" name="time" value={"1-3 months ago"} onChange={handleFormData("time")} />
                    <label htmlFor="3month">1-3 months ago</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="6month" name="time" value={"3-6 months ago"} onChange={handleFormData("time")} />
                    <label htmlFor="6month">3-6 months ago</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="12month" name="time" value={"6-12 months ago"} onChange={handleFormData("time")} />
                    <label htmlFor="12month">6-12 months ago</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="24month" name="time" value={"12-24 months ago"} onChange={handleFormData("time")} />
                    <label htmlFor="24month">12-24 months ago</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="24+month" name="time" value={"24+ months ago"} onChange={handleFormData("time")} />
                    <label htmlFor="24+month">24+ months ago</label>
                </div>
                <div className="flex justify-between w-1/5 mt-5">
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default StepFour