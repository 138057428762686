import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepNine = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();
        if (
            validator.isEmpty(values.fault)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <h2 className="w-full">Who was at fault?</h2>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="myfault" name="fault" required value={"It was my fault"} onChange={handleFormData("fault")} />
                    <label htmlFor="myfault">It was my fault</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="otherfault" name="fault" required value={"It was the other drivers fault"} onChange={handleFormData("fault")} />
                    <label htmlFor="otherfault">It was the other drivers fault</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="iamnot" name="fault" required value={"I'm not sure"} onChange={handleFormData("fault")} />
                    <label htmlFor="iamnot">I'm not sure</label>
                </div>
                <div className="flex justify-between w-1/5 mt-5">
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default StepNine