import React, { Component } from "react";
import SelectUSState from 'react-select-us-states';
import { Form, Button } from "react-bootstrap";
import validator from "validator";

class StepThree extends Component {
    constructor(props) {
        super(props);

        this.setNewValue = this.setNewValue.bind(this);
    }

    setNewValue(newValue) {
        console.log('this is the State code:' + newValue);
        this.props.handleFormData("state")({ target: { value: newValue } }); // Update the state value
    }

    render() {
        const { nextStep, values, prevStep } = this.props;

        const submitFormData = (e) => {
            e.preventDefault();

            if (validator.isEmpty(values.city) || validator.isEmpty(values.state)) {
                console.log("error");
            } else {
                nextStep();
            }
        };

        return (
            <>
                <Form onSubmit={submitFormData}>
                    <div className="flex justify-between">
                        <div className="w-[48%]">
                            <label htmlFor="states">What state did the accident occur in?</label>
                            <SelectUSState
                                id="states"
                                className="p-2 border-2 border-black w-full"
                                onChange={this.setNewValue}
                                value={values.state}
                            />
                        </div>
                        <div className="w-[48%]">
                            <label htmlFor="city">Which city did the accident occur in?</label>
                            <input
                                type="text"
                                id="city"
                                className="p-2 border-2 border-black w-full"
                                required
                                value={values.city}
                                placeholder={
                                    validator.isEmpty(values.city)
                                        ? "This is a required field"
                                        : ""
                                }
                                onChange={this.props.handleFormData("city")}
                            />
                        </div>
                    </div>
                    <div className="flex justify-between w-1/5 mt-5">
                        <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                            Previous
                        </Button>
                        <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                            Continue
                        </Button>
                    </div>
                </Form>
            </>
        );
    }
}

export default StepThree;
