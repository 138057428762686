import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import validator from "validator";

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, handleFormData, values }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    //creating error state for validation
    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();

        // checking if value of first name and last name is empty show error else take to step 2
        if (
            validator.isEmpty(values.firstName) ||
            validator.isEmpty(values.lastName) ||
            validator.isEmpty(values.tel) ||
            validator.isEmpty(values.email)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    const handlePhoneChange = (value) => {
        setPhoneNumber(value);
        handleFormData("tel")({ target: { value } });
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <div className="flex flex-wrap justify-between items-center">
                    <div className="w-full">
                        <label htmlFor="name">Name</label>
                    </div>
                    <div className="w-[48%]">
                        <input id="name" type="text" className={`${error ? "placeholder:text-red-500" : ""} border border-black w-full p-2`} required name="firstName"
                            defaultValue={values.firstName}
                            placeholder={error ? (`This is a required field`) : (``)}
                            onChange={handleFormData("firstName")} />
                        <span>First</span>
                    </div>
                    <div className="w-[48%]">
                        <input id="name" type="text" className={`${error ? "placeholder:text-red-500" : ""} border border-black w-full p-2`} required name="lastName"
                            defaultValue={values.lastName}
                            placeholder={error ? (`This is a required field`) : (``)}
                            onChange={handleFormData("lastName")}
                        />
                        <span>Last</span>
                    </div>
                </div>
                <label className={`${error ? "text-red-500" : ""}`} htmlFor="phone">{error ? (`This is a required field`) : (`Phone`)}</label>
                <div className="telinput border border-black px-2">
                    <PhoneInput
                        international
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        id="phone"
                        defaultCountry="US"
                        name={'tel'}
                    />
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" className={`${error ? "placeholder:text-red-500" : ""} border border-black w-full p-2`} required name="email"
                        defaultValue={values.email}
                        placeholder={error ? (`This is a required field`) : (``)}
                        onChange={handleFormData("email")} />
                </div>
                <div>
                    <h2 className="font-bold">GDPR Agreement</h2>
                    <div className="space-x-3">
                        <input id="accept" type="checkbox" required />
                        <label htmlFor="accept">I consent to having this website store my submitted information so they can respond to my inquiry.</label>
                    </div>
                </div>
                <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300 mt-5" type="submit">
                    Continue
                </Button>
            </Form>
        </>

    );
};

export default StepOne;
