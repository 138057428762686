import React from "react";
import { Link } from "react-scroll";
import ScrollToTopIMG from '../../../assets/images/scrolltotop.png'

function ScrollTop() {
    return (
        <>
            <Link id="scroll" activeClass='ll' to={'mastHeader'} spy={true} smooth={true} duration={700} className="cursor-pointer">
                <span>
                    <img src={ScrollToTopIMG} alt="ss" className="h-full w-full object-contain"/>
                </span>
            </Link>
        </>
    );
}

export default ScrollTop