import React from "react";
import BlogsSectionIMG from '../../assets/images/blogssection.png'
import { Link } from "react-router-dom";

function HomeThird() {
    return (
        <>
            <section className="w-full bg-fixed bg-cover bg-center h-[700px] relative max-lg:bg-left-top  max-lg:h-auto max-lg:py-20" style={{ backgroundImage: `url(${BlogsSectionIMG})` }}>
                <div className="bg-transparent bg-[linear-gradient(360deg,#153243_0%,#8E1E1E_100%)] opacity-60 h-full w-full absolute left-0 top-0 "></div>
                <div className="flex justify-center items-center h-full relative z-50 flex-col space-y-5 max-w-screen-sm mx-auto text-center max-lg:px-10">
                    <h2 className="text-white font-offside italic text-5xl">Blogs</h2>
                    <p className="text-white font-offside">
                        At MotorcyclePains, we deliver new updates and informative blogs every week. Stay tuned to see what we have to offer.
                    </p>
                    <Link to={'/blog'} className="border-2 font-offside border-white text-white py-1 px-8 hover:bg-mainColor transition-all duration-300 hover:border-transparent">
                        Blog
                    </Link>
                </div>
            </section>
        </>
    );
}

export default HomeThird