import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepFive = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();
        if (
            validator.isEmpty(values.role)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <h2 className="w-full">Were You The Driver, Passenger, or Pedestrian?</h2>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="driver" name="status" value={"Driver"} onChange={handleFormData("role")} required />
                    <label htmlFor="driver">Driver</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="passenger" name="status" value={"Passenger"} onChange={handleFormData("role")} required />
                    <label htmlFor="passenger">Passenger</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="pedestrian" name="status" value={"Pedestrian"} onChange={handleFormData("role")} required />
                    <label htmlFor="pedestrian">Pedestrian</label>
                </div>
                <div className="flex justify-between w-1/5 mt-5">
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default StepFive