import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepTwo = ({ nextStep, handleFormData, values, prevStep }) => {
    const [toggle, setToggle] = useState(false);
    const handleClick = () => {
        setToggle(!toggle);
    };

    const stopClick = () => {
        setToggle(false);
    };

    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();

        // checking if value of first name and last name is empty show error else take to step 2
        if (
            validator.isEmpty(values.moto)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <h2 className="w-full">What type of motorcycle were you in?</h2>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="standart" name="moto" value={"standart"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="standart">Standart</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="cruiser" name="moto" value={"cruiser"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="cruiser">Cruiser</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="sportBike" name="moto" value={"sportBike"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="sportBike">Sport Bike</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="touring" name="moto" value={"touring"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="touring">Touring</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="scooter" name="moto" value={"scooter"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="scooter">Scooter</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="caféRacer" name="moto" value={"caféRacer"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="caféRacer">Café Racer</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="offRoad" name="moto" value={"offRoad"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="offRoad">Off-Road</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="moped" name="moto" value={"moped"}
                        onChange={stopClick && handleFormData("moto")} />
                    <label htmlFor="moped">Moped</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="other" name="moto"
                        onChange={handleClick} />
                    <label htmlFor="other">Other</label>
                </div>
                <div className={toggle ? "Open" : "Close"}>
                    <label htmlFor="othertext">Please name the type of motorcycle.</label>
                    <input
                        type="text"
                        id="othertext"
                        className="w-full border border-black p-2"
                        value={values.mototype}
                        placeholder={error ? "This is a required field" : ""}
                        onChange={handleFormData("moto")}
                    />
                </div>
                <div className="flex justify-between w-1/5 mt-5"> 
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default StepTwo