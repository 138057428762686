import React, { useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default function Phone() {
    const [value, setValue] = useState()
    return (
        <>
            <label htmlFor="telephone">Phone Number</label>
            <div className="telinput border border-black px-2">
                <PhoneInput
                    international
                    value={value}
                    onChange={setValue}
                    id="phone"
                    defaultCountry="US"
                />
            </div>
        </>
    );
}