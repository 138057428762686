import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import validator from "validator";

const StepTen = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();
        if (
            validator.isEmpty(values.otherInsurance)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <Form onSubmit={submitFormData}>
                <h2 className="w-full">Did the other driver have insurance?</h2>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="have" name="insurance" required value={"Yes"} onChange={handleFormData("otherInsurance")} />
                    <label htmlFor="have">Yes</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="nothave" name="insurance" required value={"No"} onChange={handleFormData("otherInsurance")} />
                    <label htmlFor="nothave">No</label>
                </div>
                <div className="space-x-3 flex items-center">
                    <input type="radio" id="iamnotsure" name="insurance" required value={"I'm not sure"} onChange={handleFormData("otherInsurance")} />
                    <label htmlFor="iamnotsure">I'm not sure</label>
                </div>
                <div className="flex justify-between w-1/5 mt-5">
                    <Button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                        Previous
                    </Button>
                    <Button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default StepTen