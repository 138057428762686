import React from "react";
import { Link } from "react-router-dom";
import BlogLinkIMG from '../../assets/images/bloglink.png'
import GuidesLinkIMG from '../../assets/images/guideslink.png'
import ContactLinkIMG from '../../assets/images/contactlink.png'
import AboutSection from "./HomeSecond/aboutSection";

function HomeSecond() {
    return (
        <>
            <section className="bg-[#7A7A7A] py-10">
                <div className="max-w-screen-xl mx-auto flex space-x-5 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-5 max-lg:px-10">
                    <Link to={'/blog'} className="uppercase w-1/3 relative h-[400px] bg-cover bg-center max-lg:w-full" style={{ backgroundImage: `url(${BlogLinkIMG})` }}>
                        <div className="group h-full">
                            <div className="group-hover:opacity-0 bg-gradient-to-t from-mainColor/50 absolute left-0 top-0 w-full h-full z-10 transition-all ease-in-out duration-300"></div>
                            <div className="group-hover:opacity-100 opacity-0 bg-gradient-to-t from-mainColor/50 absolute left-0 top-0 w-full h-full z-10 transition-all ease-in-out duration-300 to-black/20"></div>
                            <div className="group-hover:text-black transition-all duration-300 flex justify-center items-center h-full font-offside text-white text-5xl ">
                                <h2 className="relative z-50 drop-shadow-md">Blog</h2>
                            </div>
                        </div>
                    </Link>
                    <Link to={'/guides'} className="uppercase w-1/3 relative h-[400px] bg-cover bg-center max-lg:w-full" style={{ backgroundImage: `url(${GuidesLinkIMG})` }}>
                        <div className="group h-full">
                            <div className="group-hover:opacity-0 bg-gradient-to-t from-mainColor/50 absolute left-0 top-0 w-full h-full z-10 transition-all ease-in-out duration-300"></div>
                            <div className="group-hover:opacity-100 opacity-0 bg-gradient-to-t from-mainColor/50 absolute left-0 top-0 w-full h-full z-10 transition-all ease-in-out duration-300 to-black/20"></div>
                            <div className="group-hover:text-black transition-all duration-300 flex justify-center items-center h-full font-offside text-white text-5xl ">
                                <h2 className="relative z-50 drop-shadow-md">Guides</h2>
                            </div>
                        </div>
                    </Link>
                    <Link to={'/contact'} className="uppercase w-1/3 relative h-[400px] bg-cover bg-center max-lg:w-full" style={{ backgroundImage: `url(${ContactLinkIMG})` }}>
                        <div className="group h-full">
                            <div className="group-hover:opacity-0 bg-gradient-to-t from-mainColor/50 absolute left-0 top-0 w-full h-full z-10 transition-all ease-in-out duration-300"></div>
                            <div className="group-hover:opacity-100 opacity-0 bg-gradient-to-t from-mainColor/50 absolute left-0 top-0 w-full h-full z-10 transition-all ease-in-out duration-300 to-black/20"></div>
                            <div className="group-hover:text-black transition-all duration-300 flex justify-center items-center h-full font-offside text-white text-5xl ">
                                <h2 className="relative z-50 drop-shadow-md">Contact</h2>
                            </div>
                        </div>
                    </Link>
                </div>
                <AboutSection />
            </section>
        </>
    );
}

export default HomeSecond