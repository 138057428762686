import React from "react";
import ContactBannerIMG from '../assets/images/contactbanner.jpg'
import MapIMG from '../assets/images/map.webp'
import { Link } from "react-router-dom";

function Contact() {
    return (
        <>
            <section className="bg-cover relative h-[450px] max-lg:bg-right" style={{ backgroundImage: `url(${ContactBannerIMG})` }}>
                <div className="bg-transparent bg-[linear-gradient(360deg,#153243_0%,#8E1E1E_100%)] opacity-60 h-full w-full absolute left-0 top-0 "></div>
                <div className="flex justify-center items-center h-full">
                    <h2 className="font-lobster text-white text-5xl relative z-50">Contact</h2>
                </div>
            </section>
            <section className="mx-auto max-w-screen-md my-20 flex flex-col space-y-5 max-lg:space-y-3 px-10">
                <div className="w-full">
                    <i className="fa-sharp fa-solid fa-quote-left text-3xl"></i>
                </div>
                <div className="p-5 border-l-4 border-[#BDBDBD]">
                    <p className="font-offside font-medium">
                        MotorcyclePains was established in <span className="font-extrabold">2020</span>, created in Los Angeles, CA by a group of motorcycle enthusiasts who’s main goal is to provide any and all information motorcycle related. We pride ourselves in our knowledge and resources.
                    </p>
                </div>
                <div className="flex justify-end">
                    <h2 className="font-lobster text-2xl">- MotorcyclePains</h2>
                </div>
            </section>
            <section className="h-[750px] max-lg:h-full max-lg:py-10 bg-cover bg-center relative" style={{ backgroundImage: `url(${MapIMG})` }}>
                <div className="bg-transparent bg-[linear-gradient(360deg,black_0%,black_100%)] opacity-60 h-full w-full absolute left-0 top-0 "></div>
                <div className="mx-auto max-w-screen-xl relative z-50 h-full flex justify-start items-center max-lg:justify-center max-lg:px-10">
                    <div className="w-2/4 bg-white h-[80%] px-10 py-5 flex space-x-5 max-lg:w-full max-lg:flex-col max-lg:h-auto max-lg:space-y-3 max-lg:space-x-0">
                        <div className="w-1/2 max-lg:w-full">
                            <form className="font-offside flex flex-col space-y-3">
                                <h2 className="font-lobster text-3xl">Send Us A Message</h2>
                                <div className="flex flex-wrap justify-between items-center">
                                    <div className="w-full">
                                        <label htmlFor="name">Name</label>
                                    </div>
                                    <div className="w-[48%]">
                                        <input id="name" type="text" className="border border-black w-full p-2" required />
                                        <span htmlFor="name">First</span>
                                    </div>
                                    <div className="w-[48%]">
                                        <input id="name" type="text" className="border border-black w-full p-2" required />
                                        <span htmlFor="name">Last</span>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="email">Email</label>
                                    <input id="email" type="email" className="border border-black w-full p-2" required />
                                </div>
                                <div>
                                    <label htmlFor="message">Message</label>
                                    <textarea id="message" className="w-full border border-black p-2" rows={8} required></textarea>
                                </div>
                                <div>
                                    <input type="submit" value={'Submit'} className="cursor-pointer uppercase py-1 px-5 border-2 border-black hover:text-white hover:bg-mainColor hover:border-transparent transition-all duration-300" />
                                </div>
                            </form>
                        </div>
                        <div className="w-1/2 flex flex-col space-y-5 max-lg:w-full max-lg:space-y-3">
                            <h2 className="font-lobster text-3xl">Get In Touch</h2>
                            <div>
                                <h3 className="font-lobster text-2xl">Address</h3>
                                <p className="font-offside">
                                    315 Al Falah St – Abu Dhabi – United Arab Emirates
                                </p>
                            </div>
                            <div>
                                <h3 className="font-lobster text-2xl">Email</h3>
                                <Link to={'mailto:info@example.com'} className="font-offside">info@example.com</Link>
                            </div>
                            <div>
                                <h3 className="font-lobster text-2xl">Phone</h3>
                                <Link to={'tel:1234567890'} className="font-offside">+1 234 567 890</Link>
                            </div>
                            <div className="flex flex-col space-y-5 max-lg:space-y-3">
                                <h3 className="font-lobster text-2xl">Follow Us</h3>
                                <ul className="flex space-x-3">
                                    <li className="w-[30px] h-[30px] bg-black rounded-2xl flex justify-center items-center hover:-translate-y-[8px] transition-all duration-500">
                                        <Link to={'https://www.instagram.com/MotorcyclePains/'} target="_blank">
                                            <i class="fa-brands fa-instagram text-white"></i>
                                        </Link>
                                    </li>
                                    <li className="w-[30px] h-[30px] bg-black rounded-2xl flex justify-center items-center hover:-translate-y-[8px] transition-all duration-500">
                                        <Link to={'https://www.facebook.com/profile.php?id=100065103300947'} target="_blank">
                                            <i class="fa-brands fa-facebook text-white"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact