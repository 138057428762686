import React from "react";
import AboutBannerIMG from '../assets/images/aboutbanner.jpg'
import AboutSectionIMG from '../assets/images/aboutimg.jpg'
import { Link } from "react-router-dom";

function About() {
    return (
        <>
            <section className="bg-cover bg-center relative h-[450px] max-lg:bg-right" style={{ backgroundImage: `url(${AboutBannerIMG})` }}>
                <div className="bg-transparent bg-[linear-gradient(360deg,#153243_0%,#8E1E1E_100%)] opacity-60 h-full w-full absolute left-0 top-0 "></div>
                <div className="flex justify-center items-center h-full">
                    <h2 className="font-lobster text-white text-5xl relative z-50">About</h2>
                </div>
            </section>
            <section className="flex max-w-screen-xl mx-auto mt-20 space-x-10 h-[600px]">
                <div className="shadow-xl shadow-black w-1/3 rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-mainColor">
                    <img src={AboutSectionIMG} alt="66" className="h-full w-full object-cover"/>
                </div>
                <div className="w-2/3 flex flex-col space-y-5">
                    <h2 className="font-offside text-5xl font-extrabold">
                        About Us
                    </h2>
                    <div className="font-offside">
                        <p>
                            MotorcyclePains was established in 2020, created in Los Angeles, CA by a group of motorcycle enthusiasts who’s main goal is to provide any and all information motorcycle related. We pride ourselves in our knowledge and resources.
                        </p>
                        <br />
                        <br />
                        <p>
                            We hope you will find everything you need here on our site. We offer a wide variety of information, from How-To guides to informative blogs and even recommended gear. If there is any information you are not able to find while you visit our site, give us an email with your question and we’ll be sure to get back to you with an answer.
                        </p>
                        <br />
                        <br />
                        <p>We look forward to seeing you again.</p>
                    </div>
                    <div className="flex flex-col space-y-5">
                        <h3 className="font-lobster text-2xl">
                            Your Friends At MotorcyclePains
                        </h3>
                        <div className="flex flex-col space-y-5 max-lg:space-y-3">
                            <h3 className="font-lobster font-bold">You can follow:</h3>
                            <ul className="flex space-x-3 text-2xl">
                                <li className="w-[50px] h-[50px] bg-black rounded-full flex justify-center items-center hover:-translate-y-[8px] transition-all duration-500">
                                    <Link to={'https://www.instagram.com/MotorcyclePains/'} target="_blank">
                                        <i class="fa-brands fa-instagram text-white"></i>
                                    </Link>
                                </li>
                                <li className="w-[50px] h-[50px] bg-black rounded-full flex justify-center items-center hover:-translate-y-[8px] transition-all duration-500">
                                    <Link to={'https://www.facebook.com/profile.php?id=100065103300947'} target="_blank">
                                        <i class="fa-brands fa-facebook text-white"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About