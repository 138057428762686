import React from "react";
import Blog1IMG from '../assets/images/blog/blog1.png'
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo.png'

function Blog() {
    return (
        <>
            <section className="w-full bg-[#7A7A7A] h-[500px]">
                <div className="flex justify-center h-full items-center">
                    <h2 className="font-lobster text-white text-5xl">Blog</h2>
                </div>
            </section>
            <section className="bg-[#F5F5F5] w-full py-20">
                <div className="mx-auto max-w-screen-xl flex space-x-5 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-5">
                    <div className="w-2/3 bg-white max-lg:w-full">
                        <div className="flex flex-col p-20 space-y-3 max-lg:p-5 ">
                            <Link to={'/single-blog'}>
                                <div className="flex flex-col justify-center items-center text-center space-y-3">
                                    <img src={Blog1IMG} alt="ll" className="h-full w-full" />
                                    <h2 className="text-4xl font-lobster text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300 max-lg:text-2xl">Pet Wrongful Death Motorcycle Accident</h2>
                                </div>
                            </Link>
                            <div className="flex space-x-2 max-lg:block">
                                <ul className="flex space-x-3 max-lg:flex-wrap">
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Accidents</Link>
                                        <span className="text-black">,</span>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Guides</Link>
                                        <span className="text-black">,</span>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Legal</Link>
                                    </li>
                                </ul>
                                <span className="font-offside text-[#9C9A98]">/ By</span>
                                <Link to={'single-author'} className="text-[#9C9A98] font-offside hover:text-black transition-all duration-300">motorcyclepainsdm</Link>
                            </div>
                            <div>
                                <p className="font-offside">
                                    Wrongful death bike accidents caused by pets are a heart-wrenching event that can leave a profound impact on those involved. These accidents occur when a motorcyclist collides with a pet, often resulting in severe injuries or even death. Pet owners can prevent such mishaps by taking responsibility htmlFor their animals and keeping them under control. …
                                </p>
                            </div>
                            <div>
                                <Link to={'/single-blog'} className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">Read More »</Link>
                            </div>
                        </div>
                        <hr />
                        <div className="flex flex-col p-20 space-y-3 max-lg:p-5 ">
                            <Link to={'/single-blog'}>
                                <div className="flex flex-col justify-center items-center text-center space-y-3">
                                    <img src={Blog1IMG} alt="ll" className="h-full w-full" />
                                    <h2 className="text-4xl font-lobster text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300 max-lg:text-2xl">Pet Wrongful Death Motorcycle Accident</h2>
                                </div>
                            </Link>
                            <div className="flex space-x-2 max-lg:block">
                                <ul className="flex space-x-3 max-lg:flex-wrap">
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Accidents</Link>
                                        <span className="text-black">,</span>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Guides</Link>
                                        <span className="text-black">,</span>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Legal</Link>
                                    </li>
                                </ul>
                                <span className="font-offside text-[#9C9A98]">/ By</span>
                                <Link to={'single-author'} className="text-[#9C9A98] font-offside hover:text-black transition-all duration-300">motorcyclepainsdm</Link>
                            </div>
                            <div>
                                <p className="font-offside">
                                    Wrongful death bike accidents caused by pets are a heart-wrenching event that can leave a profound impact on those involved. These accidents occur when a motorcyclist collides with a pet, often resulting in severe injuries or even death. Pet owners can prevent such mishaps by taking responsibility htmlFor their animals and keeping them under control. …
                                </p>
                            </div>
                            <div>
                                <Link to={'/single-blog'} className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">Read More »</Link>
                            </div>
                        </div>
                        <hr />
                        <div className="flex flex-col p-20 space-y-3 max-lg:p-5 ">
                            <Link to={'/single-blog'}>
                                <div className="flex flex-col justify-center items-center text-center space-y-3">
                                    <img src={Blog1IMG} alt="ll" className="h-full w-full" />
                                    <h2 className="text-4xl font-lobster text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300 max-lg:text-2xl">Pet Wrongful Death Motorcycle Accident</h2>
                                </div>
                            </Link>
                            <div className="flex space-x-2 max-lg:block">
                                <ul className="flex space-x-3 max-lg:flex-wrap">
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Accidents</Link>
                                        <span className="text-black">,</span>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Guides</Link>
                                        <span className="text-black">,</span>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Legal</Link>
                                    </li>
                                </ul>
                                <span className="font-offside text-[#9C9A98]">/ By</span>
                                <Link to={'single-author'} className="text-[#9C9A98] font-offside hover:text-black transition-all duration-300">motorcyclepainsdm</Link>
                            </div>
                            <div>
                                <p className="font-offside">
                                    Wrongful death bike accidents caused by pets are a heart-wrenching event that can leave a profound impact on those involved. These accidents occur when a motorcyclist collides with a pet, often resulting in severe injuries or even death. Pet owners can prevent such mishaps by taking responsibility htmlFor their animals and keeping them under control. …
                                </p>
                            </div>
                            <div>
                                <Link to={'/single-blog'} className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">Read More »</Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 max-lg:w-full">
                        <div className="flex flex-col space-y-5">
                            <div className="bg-white p-10 flex flex-col space-y-3">
                                <h2 className="font-lobster text-3xl">Contact Us</h2>
                                <form className="font-offside flex flex-col space-y-3">
                                    <div>
                                        <label htmlFor="name">Your Name</label>
                                        <input id="name" type="text" className="border border-black w-full p-2" required />
                                    </div>
                                    <div>
                                        <label htmlFor="tel">Your phone number</label>
                                        <input id="tel" type="tel" className="border border-black w-full p-2" required />
                                    </div>
                                    <div>
                                        <label htmlFor="email">Your email</label>
                                        <input id="email" type="email" className="border border-black w-full p-2" required />
                                    </div>
                                    <div>
                                        <label htmlFor="subject">Subject</label>
                                        <input id="subject" type="text" className="border border-black w-full p-2" required />
                                    </div>
                                    <div>
                                        <label htmlFor="message">Your message (optional)</label>
                                        <textarea id="message" className="w-full border border-black p-2" rows={8}></textarea>
                                    </div>
                                    <div>
                                        <input type="submit" value={'Submit'} className="cursor-pointer uppercase py-1 px-5 border-2 border-black hover:text-white hover:bg-mainColor hover:border-transparent transition-all duration-300" />
                                    </div>
                                </form>
                            </div>
                            <div className="bg-white p-10 flex flex-col space-y-3">
                                <h2 className="font-lobster text-3xl">Categories</h2>
                                <ul className="flex flex-col space-y-3">
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Accidents</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Gear</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Guides</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Injuries</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Lane splitting</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Legal</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Maintenance</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Motorcycle Riding</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Safety</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Street racing</Link>
                                    </li>
                                    <li className="font-offside text-[#1A6C7A] hover:text-[#AFCEDC] transition-all duration-300">
                                        <Link to={'/single-category'}>Travel</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-10 bg-white">
                                <div className="relative">
                                    <input className="border border-black w-full p-2 focus:outline-none" type="search" name="search" placeholder="Search" />
                                    <button type="submit" className="absolute right-0 top-[13px] mr-4">
                                        <svg className="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style={{ enableBackground: 'new 0 0 56.966 56.966' }} xmlSpace="preserve" width="512px" height="512px">
                                            <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                                        </svg>
                                    </button>
                                </div>

                            </div>
                            <div className="p-10 bg-white">
                                <img src={Logo} alt="uu" className="w-full h-full" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog